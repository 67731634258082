// Global module, sub-modules, wrapper for extending objects
// Example usage:
// See PESAPP.sponsor.init

var PESAPP = (function(m, $) {

  m.templateCache = m.templateCache || {};

  m.extend = function(model, config) {

    return $.extend(true, {}, model, config);
  };

  return m;

}(PESAPP || {}, jQuery));
