// Add form contollers to a Handlebars view
// Example usage:
// var sponsors = PESAPP.extend(PESAPP.controller, config).controllerInit();

PESAPP.controller = (function(m, $) {

  m.options = {
    controllers: {
      filter: {
        radio: false,
        selector: '[data-filter]',
        initial: {
          selector: '#initial-filter',
          class: 'active'
        }
      },
      keyword: {
        filters: '',
        selector: '[data-keyword]'
      },
      reset: { selector: '[data-reset]' },
      sort: {
        selector: '[data-sort]',
        initial: {
          selector: '#initial-sort',
          class: 'active ascending'
        }
      },
      hash: { selector: '[data-hash]' }
    }
  };

  m.controllerInit = function(always) {
    var self = this;
    var view = $(document).find(self.controller);
    var data = self.data;
    var options = self.options;
    var controllers = options.controllers;
    var filter = controllers.filter;
    var hash = controllers.hash;
    var keyword = controllers.keyword;
    var reset = controllers.reset;
    var sort = controllers.sort;
    var filterEl = view.find(filter.selector);
    var keywordEl = view.find(keyword.selector);
    var resetEl = view.find(reset.selector);
    var sortEl = view.find(sort.selector);
    var filterRadio = filter.radio ? filterEl : false;
    var initialFilter = filter.initial;
    var initialSort = sort.initial;

    filterEl.on('click', function(event) {
      var target = $(this);

      _resetButtons(filterRadio, target, 'active');

      data.items = _filterHelper(data.model, filterEl);
      data.items = _keywordHelper(data.items, keyword.filters, keywordEl);
      data.single = data.items.length === 1 ? true : false;
      _finalize(event);
    });

    // hashEl
    $(document).on('click', hash.selector, function(event) {
      var target = $(this);

      location.hash = PESAPP.hash.get(target);
      event.preventDefault();
    });

    keywordEl.on('keyup', function(event) {
      data.items = _keywordHelper(data.model, keyword.filters, keywordEl);
      data.items = _filterHelper(data.items, filterEl);
      data.single = data.items.length === 1 ? true : false;
      _finalize(event);
    });

    resetEl.on('click', function(event) {
      PESAPP.hash.clear();
      keywordEl.val('');

      _resetAllButtons();

      data.items = PESAPP.sort(data.model, options.sorts);

      if (options.filters) {
        data.items = PESAPP.filter(data.items, options.filters);
      }

      data.single = data.items.length === 1 ? true : false;
      _finalize(event);
    });

    sortEl.on('click', function(event) {
      var target = $(this);
      var toggle = !target.hasClass('ascending');
      var addClass = toggle ? 'active ascending' : 'active descending';
      var sorts = '';

      // DOM manipulation
      _resetButtons(sortEl, target, addClass);

      sortEl.each(function(s, sort) {
        sort = $(sort);
        var separator = ',';

        if (sort.hasClass('active')) {
          if (sorts.length > 0) { sorts += separator; }
          sorts += sort.data('sort');
        }
      });

      sorts = sorts + ',' + options.sorts;
      sorts = !toggle ? '-' + sorts : sorts;
      data.items = PESAPP.sort(data.items, sorts);
      _finalize(event);
    });

    function _finalize(event) {

      if (always && typeof always === 'function') { always(self); }
      self.updateTemplate();
      event.preventDefault();
    }

    function _resetAllButtons() {
      _resetButtons(filterEl, $(initialFilter.selector), initialFilter.class);
      _resetButtons(sortEl, $(initialSort.selector), initialSort.class);
    };
    _resetAllButtons();

  };

  function _keywordHelper(items, filters, target) {
    var keywordVal = target.val();
    var filtersA = [];

    if (keywordVal) {
      $.each(filters.split(','), function(f, filter) {
        filtersA.push({key: filter, value: keywordVal });
      });

      items = PESAPP.filter(items, filtersA);
    }

    return items;
  }

  function _filterHelper(items, target) {
    var filters = [];

    target.each(function(f, filter) {
      filter = $(filter);

      if (filter.hasClass('active')) {
        filters.push({ key: filter.data('filter'), value: filter.data('value') });
      }
    });

    if (filters.length > 0) {
      items = PESAPP.filter(items, filters);
    }

    return items;
  }

  function _resetButtons(buttons, target, addClass) {
    if (buttons) {
      $(buttons).each(function() {
        $(this)
          .removeClass('active')
          .removeClass('ascending')
          .removeClass('descending');
      });

      if (target) { target.addClass(addClass); }
    }
    else {
      if (target) { target.toggleClass(addClass); }
    }
  }

  return m;

}(PESAPP.controller || {}, jQuery));
