// Wrapper for the exhibitor API that returns exhibitors with products only
// Example usage:
// var product = PESAPP.extend(PESAPP.product, config).init();

PESAPP.product = (function(m, $) {

  m.options = {
    sorts: 'Name',
    template: 'base-product-list',
    filters: [],
    excludes: [],
    ajax: {
      dataType: 'jsonp',
      callback: _callback
    },
    controllers: {
      keyword: {
        filters: 'Name,CompanyName,Description,Detail,BoothNumber,ProductTypes'
      }
    }
  };

	m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.controller, parent);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.hash, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();

      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        self.data.items = holder.items;
        self.options.ajax.callback(self);
        self.data.items = _filter(self.data.items, self.options);
        self.updateTemplate();
        self.controllerInit();
      });

      return self;
    });
	};

  function _callback(self) {
    var data = self.data;
    var options = self.options;
    data.items = _mapItems(data.items, options.sorts);
    data.model = data.items;
    data.items = PESAPP.hash.check('NameSlug', data.items, data.model);
    data.single = data.items.length === 1 ? true : false;

    return self;
  }

  function _mapItems(items, sorts) {
    var mapped = [];
    var products;

    $.each(items, function(i, item) {
      products = $(item.ProductInformation.Products).toArray();
      delete item.ProductInformation;

      if (products.length > 0) {
        $.each(products, function(p, product) {
          product.LargeImageURL = product.LargeImageURL.length === 0 ? '' : product.LargeImageURL;
          product.Detail = PESAPP.stripHtml(product.Detail);
          product.NameSlug = PESAPP.slugify(product.Name);
          product = PESAPP.extend(item, product);
          mapped.push(product);
        });
      }
    });

    mapped = PESAPP.sort(mapped, sorts);

    return mapped;
  }

  function _filter(items, options) {
    var filtered = [];

    if (
      typeof options.filters !== "undefined" &&
      options.filters.length > 0
    ) {
      return PESAPP.filter(items, options.filters);
    }
    else if (
      typeof options.excludes !== "undefined" &&
      options.excludes.length > 0
    ) {
      $.each(items, function(i, item) {
        $.each(options.excludes, function(ii, exclude) {
          if (item[exclude.key].indexOf(exclude.value) === -1) {
            filtered.push(item);
          }
        });
      });
      return filtered;
    }
    else {
      return items;
    }
  }

  return m;

}(PESAPP.product || {}, jQuery));
