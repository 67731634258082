(function ($) {
  'use strict';
  $.fn.extCarousel = function (settings) {
    return this.each(function () {

      if (!$.fn.carousel) throw new Error('sponsor requires carousel.js');

      var $parent = $(this),
        s = $.extend({
          controls: true,
          indicators: true,
          interval: 5000,
          fade: false
        }, settings),

        $indicators = $parent.find('.carousel-indicators'),
        $items = $parent.find('.item'),
        id = $parent.attr('id'),

        theInterval = $parent.data('extcarousel-interval') ? $parent.data('extcarousel-interval') : s.interval;

      var isTouchDevice = function () {
        return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
      };

      function controlsTemplate(type) {
        var srSlide = (type === 'left') ? 'Previous' : 'Next',
          slide = (type === 'left') ? 'prev' : 'next',
          controlHtml =
            '<a class="'+ type + ' carousel-control" href="#' + id + '" role="button" data-slide="' + slide + '">' +
              '<span class="glyphicon glyphicon-chevron-' + type + '"></span>' +
              '<span class="sr-only">' + srSlide + '</span>' +
            '</a>';

        $parent.append(controlHtml);
      }

      function indicatorsTemplate() {
        var i,
          active,
          indicatorHtml = '';

        for (i = 0; i < $items.length; i += 1) {
          active = (i === 0) ? ' class="active"' : '';
          indicatorHtml += '<li data-target="#' + id + '" data-slide-to="' + i + '"' + active + '></li>';
        }

        $indicators.append(indicatorHtml);
      }

      $(function () {
        var $firstItem = $items.first();

        if (!$firstItem.hasClass('active')) $firstItem.addClass('active');

        if (s.controls) {
          controlsTemplate('left');
          controlsTemplate('right');
        }

        if (s.indicators) {
          indicatorsTemplate();
        }
      });

      function touchyDrag(phase, data) {
        if (phase === 'move') {
          var yDelta = data.movePoint.y - data.lastMovePoint.y,
            xDelta = data.movePoint.x - data.lastMovePoint.x;

          if (yDelta > -8 && yDelta < 8) {
            if (xDelta < 0 && xDelta !== 0) $('#' + id).carousel('next');
            else if (xDelta > 0 && xDelta !== 0) $('#' + id).carousel('prev');
          }
        }
      }

      // if touchy library is loaded
      if ('touchy-drag') $items.find('*').on('touchy-drag', touchyDrag);

      if (s.fade && !isTouchDevice()) { $parent.addClass('carousel-fade'); }

      $parent.carousel({
        interval: theInterval
      });

    });
  };

}(jQuery));
