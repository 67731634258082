// General methods

PESAPP = (function(m, $) {

  m.filter = function(array, filters) {
    var filtered = [];

    $.each(array, function(i, item) {
      $.each(filters, function(f, filter) {
        var key   = filter.key;
        var value = filter.value.toString().toLowerCase();

        if (
          key in item &&
          item[key].length !== 0 &&
          item[key].toString().toLowerCase().indexOf(value) > -1
        ) {
          filtered.push(item);

          return false;
        }
      });
    });

    return filtered;
  };

  m.randomize = function(array) {
    for (var j, x, i = array.length; i; j = parseInt(Math.random() * i, 10), x = array[--i], array[i] = array[j], array[j] = x);

    return array;
	};

  m.slugify = function slugify(input, delimiter) {
    delimiter = delimiter || '-';

    return input
      .toString()
      .toLowerCase()
      .replace(/[^\w ]+/g, ' ')   // replace non-metacharacters w/ws
      .trim()                     // strip extra ws
      .replace(/ +/g, delimiter); // replace ws w/delimiter
  };

  m.sort = function(items, sorts) {
    sorts = sorts ? sorts.split(',') : [];

    if (sorts.indexOf('randomize') > -1) {
      items = m.randomize(items);
    }
    else {
      items = _sortHelper(items, sorts);
    }

    return items;
  };

  m.stripHtml = function(html, callback) {

    html = html && html.toString()
      .replace(/(<([^>]+)>)/ig, ' ')
      .replace(/\s+/g, ' ')
      .trim()
      .replace(/(?:\r\n|\r|\n)/g, ' ');

    if (callback && typeof callback === 'function') { html = callback(html); }

    return html;
  };

  function _sortHelper(items, sorts) {
    var sorted = [];
    var ascending = true;
    var number = false;

    // Check if ascending or descending based on leading dash -
    if (sorts[0].charAt(0) === '-') {
      sorts[0] = sorts[0].substr(1, sorts[0].length);

      if (ascending) { ascending = false; }
    }

    $.each(items, function(i, item) {
      item.sortConcat = '';

      $.each(sorts, function(s, sort) {

        if (!number) {

          // If sorting by numbers, break the sorts loop and just use simple sort
          if (typeof item[sort] === 'number') {
            number = true;
            item.sortConcat = item[sort];

            return false;
          }
          else {
            if (sort in item) {
              if (typeof item[sort] === 'boolean') {
                item.sortConcat += item[sort] ? '2' : '1';
              }
              else {
                item.sortConcat += item[sort].toLowerCase() + ' ';
              }
        		}
          }
        }
  		});

      if (!number) {
  		  item.sortConcat = item.sortConcat.trim();
      }
      // Simple sort for numbers uses the first sort item only
      else {
        item.sortConcat = item[sorts[0]];
      }
    });

    sorted = items.sort(function(a, b) {
  		var aProp = a.sortConcat;
  		var bProp = b.sortConcat;

      // Ascending mode
      if (ascending) {
        if (aProp < bProp) { return -1; }
  			if (aProp > bProp) { return 1; }
  			else { return 0; }
  		}
      // Descending mode
      else {
        if (aProp < bProp) { return 1; }
    		if (aProp > bProp) { return -1; }
    		else { return 0; }
      }
  	});

    return sorted;
  }

  return m;

}(PESAPP || {}, jQuery));
