(function ($) {
  'use strict';

  $.fn.scrollAnimate = function (settings) {
    return this.each(function () {
      var s = $.extend({
          offset : 0,
          duration : 500
        }, settings),

        offset = $(this).data('scrollto-offset') ? $(this).data('scrollto-offset') : s.offset,
        duration = $(this).data('scrollto-duration') ? $(this).data('scrollto-duration') : s.duration,
        target;

      function scrollAction(target) {
        $('html,body').animate({
          scrollTop : $(target).offset().top - offset
        }, duration);
        return false;
      }

      $(this).on('click', function (e) {
        e.preventDefault();
        target = $(this).attr('href');
        scrollAction(target);
      });
    });
  };
}(jQuery));
