// Wrapper for sponsor API
// Example usage:
// var sponsor = PESAPP.extend(PESAPP.sponsor, config).init();

PESAPP.sponsor = (function(m, $) {

  m.options = {
    logosPerSlide: 1,
    logoLinks: false,
    sorts: 'title',
    template: 'base-sponsor-list-description',
    ajax: {
      dataType: 'jsonp',
      callback: _callback
    }
  };

	m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function(v, view) {
      view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();
      var searchString = window.location.search;
      self.id = "sponsor-carousel-" + v;
      self.options.showLabels = (searchString.indexOf("show-labels") > -1 || searchString.indexOf("showmsg=1") > -1) ? true : false;

      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        self.data.items = holder.items;
        self.options.ajax.callback(self);
        self.updateTemplate();
        if (self.options.toggleAll) {
          $(self.options.toggleAll).collapseToggleAll();
        }
      });
    });
	};

  function _callback(self) {
    var data = self.data;
		var options = self.options;
    data.items = _mapItems(data.items, options);
    data.model = data.items;
    data.levels = _mapLevelItems(data.items, options.levels);
    data.modelLevels = data.levels;

    return self;
  }

  function _mapItems(items, options) {
    var levels = options.levels;
    var sorts = options.sorts;
    var mapped = [];
    var filters = [];

    $.each(items, function(i, item) {
      item.sponsor_level_slug = PESAPP.slugify(item.field_logo_sponsor_level);
      item.title_slug = PESAPP.slugify(item.title);
      item.field_logo_opp_status = _returnStatus(item.field_logo_opp_status);
      item.field_logo_opp_three_status = _returnStatus(item.field_logo_opp_three_status);
      item.field_logo_opp_two_status = _returnStatus(item.field_logo_opp_two_status);
      item.field_sponsor_booth_url_status = _returnStatus(item.field_sponsor_booth_url_status);
      item.field_sponsor_website_status = _returnStatus(item.field_sponsor_website_status);

      if (item.sponsor_level_slug in options) {
        item.options = options[item.sponsor_level_slug];
      }
    });

		if (levels) {
		  levels = _getLevels(items, levels);
      items = PESAPP.sort(items, sorts);

			$.each(levels, function(l, level) {
				$.each(items, function(i, item) {
					if (item.sponsor_level_slug === level) { mapped.push(item); }
				});
				filters.push({key: 'sponsor_level_slug', value: level});
			});
			mapped = PESAPP.filter(mapped, filters);
		}
		else {
      mapped = items;
      items = PESAPP.sort(items, sorts);
    }

    return mapped;
  }

  function _returnStatus(status) {
    return status === '1' ? true : false;
  }

  function _getLevels(items, levels) {
    levels = levels ? levels.split(',') : [];

    if (levels.length === 0) {
      $.each(items, function(i, item) {
        if (levels.indexOf(item.sponsor_level_slug) === -1) {
          levels.push(item.sponsor_level_slug);
        }
      });
    }

    return levels;
  }

  function _mapLevelItems(items, levels) {
  	levels = _getLevels(items, levels);
    var groups = {};

    $.each(levels, function(l, level) {
      groups[level] = {};
      groups[level].items = [];
    });

    $.each(items, function(i, item) {
		  groups[item.sponsor_level_slug].items.push(item);
    });

    return groups;
  }

  return m;

}(PESAPP.sponsor || {}, jQuery));
