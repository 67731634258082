PESAPP.gallery = (function(m, $) {

  m.options = {
    template: 'base-gallery'
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.htmlModel, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function(v) {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view, id: v });

      self.compileModel();
      self.getTemplate();
      self.updateTemplate();
      _galleryController(self);

      return self;
    });
  };

  function _galleryController(self) {
    var itemId = self.id;
    var view = $(self.view);
    var thumbnail = view.find('#gallery-thumbnails-'+ itemId);
    var modal = view.find('#gallery-modal-'+ itemId);
    var modalItems = modal.find('.carousel-inner').find('.item');
    var active = findActive();

    // Set up carousels
    thumbnail.carousel({ interval: false });
    modal.carousel({ interval: false });

    modal.on('slid.bs.carousel', function() {
      active = findActive();
    });

    modal.on('slide.bs.carousel', function() {
      stopVideoPlayback(active);
    });

    modal.on('hide.bs.modal', function() {
      stopVideoPlayback(active);
    });

    thumbnail.on('click', 'a', function(event) {
      var setActive = $(event.target).closest('a').data('index');

      $(modalItems[active]).removeClass('active');
      $(modalItems[setActive]).addClass('active');
      active = setActive;

      modal.modal();
      event.preventDefault();
    });

    function findActive() {
      return modalItems.filter('.active').index();
    }

    function stopVideoPlayback(index) {
      var temp = $(modalItems[index]).find('iframe');
      var tempSrc;

      if (temp.length > 0) {
        tempSrc = temp.attr('src');
        temp.attr('src', '').attr('src', tempSrc);
      }
    }
  }

  return m;

}(PESAPP.gallery || {}, jQuery));
