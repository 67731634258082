(function ($) {
  'use strict';

  $.fn.collapseToggleAll = function (options) {
    return this.each(function () {
      var toggleEl = $(this);

      var o = $.extend({
        wrapperEl: $(toggleEl.data('wrapper')) || false,
        toggleShowText: toggleEl.data('show-text') || '<i class="fa fa-plus"></i> Show All',
        toggleHideText: toggleEl.data('hide-text') || '<i class="fa fa-minus"></i> Hide All',
        hideInitial: !toggleEl.data('hide-initial') ? false : true // default is false
      }, options);

      var wrapperEl = $(o.wrapperEl);
      var collapseEl = wrapperEl.find('.collapse');
      var totalLen = collapseEl.length;
      var shownLen;
      var showMode = o.hideInitial;
      var changed = $.Event('changed.collapseToggleAll', {
        target: toggleEl
      });

      var countShown = function () {

        return collapseEl.filter('.in').length;
      };

      var toggleMode = function () {

        if (shownLen === totalLen || shownLen === 0) {
          wrapperEl.trigger(changed);
          showMode = !showMode;
        }
      };

      var toggleAllLabel = function () {

        if (showMode) { toggleEl.html(o.toggleShowText); }
        else { toggleEl.html(o.toggleHideText); }
      };

      var toggleAll = function () {
        var mode = !showMode ? 'show' : 'hide';

        collapseEl.each(function () {
          var thisCollapse = $(this);
          var thisToggle = thisCollapse.parent().find('[data-toggle="collapse"]');

          thisCollapse.collapse(mode);

          if (mode === 'show') { thisToggle.removeClass('collapsed'); }
          else if (mode === 'hide') { thisToggle.addClass('collapsed'); }
        });
      };

      wrapperEl.on('shown.bs.collapse hidden.bs.collapse', function () {
        shownLen = countShown();

        toggleMode();
      });

      wrapperEl.on('changed.collapseToggleAll', function () {
        showMode = shownLen ? false : true;

        toggleAllLabel();
      });

      toggleEl.on('click', function (event) {
        toggleAll();

        event.preventDefault();
      });
    });
  };
}(jQuery));
