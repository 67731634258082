// Wrapper for the attendee API
// Example usage:
// var sponsors = PESAPP.extend(PESAPP.attendee, config).init();

PESAPP.attendee = (function(m, $) {

  m.options = {
    sorts: 'company,jobtitle',
    template: 'base-attendee-list',
    ajax: {
      dataType: 'jsonp',
      callback: _callback
    },
    controllers: {
      keyword: {
        filters: 'company,jobtitle'
      }
    }
  };

	m.init = function() {
	  var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.controller, parent);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();

      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        self.data.items = holder.items;
        self.options.ajax.callback(self);
        self.updateTemplate();
        self.controllerInit();
      });

      return self;
    });
	};

  function _callback(self) {
    var data = self.data;
    var options = self.options;

    data.items = _mapItems(data.items, options.defaultImg);
    data.items = PESAPP.sort(data.items, options.sorts);
    data.model = data.items;
    data.single = data.items.length === 1 ? true : false;

    return self;
  }

  function _mapItems(items, defaultImg) {
    var mapped = [];

    $.each(items, function(i, item) {
      if (item.photoUrl) {
        item.photoUrl = _decodePhotoUrl(item.photoUrl);
      }
      else if(defaultImg) {
        item.photoUrl = defaultImg;
      }
      mapped.push(item);
    });

    return mapped;
  }

  function _decodePhotoUrl(url) {
    var baseUrl = 'https://www.eiseverywhere.com/ereg/uploadfiles/';
    var encoded = url.match(/upload.php\?(.*?)"/)[1];
    var decoded = atob(encoded);

    return baseUrl + decoded;
  }

  return m;

}(PESAPP.attendee || {}, jQuery));
