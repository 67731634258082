PESAPP.session = (function(m, $) {

  m.options = {
    template: "base-session-list",
    sorts: "StartDate,Title",
    published: true,
    dates: [],
    filters: [],
    controllers: {
      keyword: {
        filters: "Title,Description"
      }
    },
    baseLink: {
      session: false,
      speaker: false
    },
    noResults: "<strong>Sorry</strong> Nothing to see here",
    ajax: {
      dataType: "jsonp"
    }
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.controller, parent);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();
      self.getTemplate();

      promise.on("dataReady", function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {"items": response});
        var filtered = _filter(holder.items, self.options);
        self.data.items = filtered;
        self.data.model = filtered;
        self.data.single = self.data.items.length === 1;
        _getCount(self);
        console.log(self.data);
        self.updateTemplate();
        self.controllerInit(function() {
          _getCount(self);
          self.data.single = self.data.items.length === 1;
        });
      });
    });
  };

  function _getCount(self) {
    self.data.count = 0;
    if (self.data.items.length !== 0) {
      $.each(self.data.items, function(i, item) {
        self.data.count += item.length;
      });
    }
  }

  function _filter(items, options) {
    var filtered = items;
    var grouped = {};
    var dates = [];

    // Check if session is published
    if (options.published) {
      filtered = PESAPP.filter(filtered, [
        {key: "Published", value: "YES"}
      ]);
    }

    // Check if session date is displayed
    if (
      typeof options.dates !== "undefined" &&
      options.dates.length
    ) {
      $.each(options.dates, function(d, date) {
        dates.push({key: "StartDate", value: date});
      });
      filtered = PESAPP.filter(filtered, dates);
    }

    // Manipulate track info
    $.each(filtered, function(i, item) {
      item.trackNames = "";
      item.trackIDs = "";

      // Use PESAPP.dateFormatter to generate pretty dates
      item.displayDate = {
        start: PESAPP.dateFormatter.format(item.StartDate),
        end: PESAPP.dateFormatter.format(item.EndDate)
      };

      // Assign tracks to root for sorting/filtering
      if (typeof item.SessionTrackList.SessionTrack !== 'undefined') {
        $.each(item.SessionTrackList.SessionTrack, function(t, track) {
          item.trackIDs += item.trackIDs.length > 1 ? ", " + track["@TrackID"] : track["@TrackID"];
          item.trackNames += item.trackNames.length > 1 ? ", " + track.Track : track.Track;
        });
      }

      // Manipulate speaker info
      if (
        typeof item.SessionSpeakerList.SessionSpeaker !== "undefined" &&
        !Array.isArray(item.SessionSpeakerList.SessionSpeaker)
      ) {
        var temp = item.SessionSpeakerList.SessionSpeaker;
        item.SessionSpeakerList.SessionSpeaker = [];
        item.SessionSpeakerList.SessionSpeaker.push(temp);
      }
    });

    if (
      typeof options.filters !== "undefined" &&
      options.filters.length > 0
    ) {
      filtered = PESAPP.filter(filtered, options.filters);
    }

    // Use PESAPP.sort to sort the array
    filtered = PESAPP.sort(filtered, options.sorts);

    // Group filtered items by date
    $.each(filtered, function(f, fItem) {
      // Split StartDate on space to get date without time
      var date = fItem.StartDate.split(" ")[0];

      // Create date groups
      grouped[date] = typeof grouped[date] !== "undefined" ? grouped[date] : [];
      grouped[date].push(fItem);
    });

    return grouped;
  }

  return m;

}(PESAPP.session || {}, jQuery));
