PESAPP.sessionSponsor = (function(m, $) {

  m.options = {
    logosPerSlide: 1,
    logoLinks: false,
    sorts: "randomize",
    template: "base-session-sponsor-carousel",
    sizeClass: "gold",
    ajax: {
      dataType: "jsonp"
    }
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function(v, view) {
      view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();
      self.sessionId = view.data("sessionId");
      self.id = "session-sponsor-carousel-" + v;
      self.getTemplate();

      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        var filtered = _filter(holder.items, self.sessionId, self.options);
        self.data.items = filtered;
        self.data.model = filtered;
        self.updateTemplate();
      });
    });
  };

  function _filter(items, sessionId, options) {
    var filtered = [];
    // Sort items
    items = PESAPP.sort(items, options.sorts);
    // Filter by session Id
    $.each(items, function(i, item) {
    	if (
      	typeof item.sessionid !== "undefined" &&
      	item.sessionid.indexOf(sessionId) > -1
      ) {

        item.sponsor_level_slug = PESAPP.slugify(item.field_logo_sponsor_level);
        if (item.sponsor_level_slug in options) {
          item.options = options[item.sponsor_level_slug];
        }
      	filtered.push(item);
      }
    });
    return filtered;
  }

  return m;

}(PESAPP.sessionSponsor || {}, jQuery));
