PESAPP.sponsorOpps = (function(m, $) {

  m.options = {
    soldBottom: true,
    featuredTop: true,
    sorts: 'title,price',
    template: 'base-sponsor-opps',
    controllers: {
      keyword: {
        filters: 'title,content,excerpt,level'
      }
    },
    contact: {
      link: '',
      text: 'Contact Sales'
    },
    typeConfig: {
      propertyName: '',
    },
    levelConfig: {
      propertyName: '',
      levels: {}
    }
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.controller, parent);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();

      // sort stuff
      var data = self.data;
      var options = self.options;

      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        self.data.items = _mapItems(holder.items.posts, options);
        self.data.model = self.data.items;
        self.data.items = PESAPP.sort(self.data.items, options.sorts);
        self.data.items = _temp(self.data.items, options);
        self.data.items = PESAPP.hash.check('slug', self.data.items, self.data.model);
        self.updateTemplate();
        self.controllerInit();
      });
    });
  };

  function _temp(items, options) {
    var featured = [];
    var sold = [];
    var basic = [];

    $.each(items, function(i, item) {
      if (options.soldBottom && typeof item.custom_fields.opp_sold !== 'undefined') {
        sold.push(item);
      }
      else if (options.featuredTop && typeof item.custom_fields.opp_featured !== 'undefined') {
        featured.push(item);
      }
      else {
        basic.push(item);
      }
    });

    return featured.concat(basic, sold);
  }

  function _mapItems(items, options) {
    var lConfig = options.levelConfig;
    var lLevels = lConfig.levels;
    var lProp = lConfig.propertyName;
    var tConfig = options.typeConfig;
    var tProp = tConfig.propertyName;

    $.each(items, function(i, item) {
      item.level = '';
      item.type = '';
      item.price = '';
      item.sold = false;
      item.featured = false;
      item.levelSort = 'zzz';

      if (typeof item.custom_fields[lProp] !== 'undefined') {
        // Take the first item from the array, only 1 item is allowed
        item.level = item.custom_fields[lProp][0];
        item.levelSlug = PESAPP.slugify(item.level);
        item.levelConfig = lLevels[item.level] || {};
        item.levelSort = item.levelConfig.sort || item.custom_fields[lProp][0];

        if (typeof item.levelConfig.label !== 'undefined') {
          item.level = item.levelConfig.label;
        }
        if (typeof item.levelConfig.class !== 'undefined') {
          item.levelClass = item.levelConfig.class;
        }
      }

      if (item.custom_fields[tProp]) {
        $.each(item.custom_fields[tProp], function(ii, type) {
          $.each(item.custom_fields[tProp]['0'], function(iii, type) {
            item.type += type + ' ';
          });
        });
      }

      if (item.custom_fields.opp_sold) {
        $.each(item.custom_fields.opp_sold, function(ii, sold) {
          item.sold = true;
        });
      }

      if (item.custom_fields.opp_featured) {
        $.each(item.custom_fields.opp_featured, function(ii, featured) {
          item.featured = true;
        });
      }

      if (item.custom_fields.opp_numeric_cost) {
        $.each(item.custom_fields.opp_numeric_cost, function(ii, opp_numeric_cost) {
          item.price = item.custom_fields.opp_numeric_cost.toString();
          item.price = parseInt(item.price);
        });
      }
    });

    return items;
  }

  return m;

}(PESAPP.sponsorOpps || {}, jQuery));
