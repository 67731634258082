(function ($) {
  'use strict';

  $.fn.navbarOffcanvas = function (settings) {
  return this.each(function () {
    var s = $.extend({
      toggleTrigger: '.offcanvas-trigger',
      target: '.navbar-offcanvas',
      toggle: 'in'
    }, settings),

    navbarEl    = $(this),
    offcanvas   = navbarEl.data('offcanvas-target') ? navbarEl.data('offcanvas-target') : s.target,
    offcanvasEl = navbarEl.find(offcanvas).first(),
    triggerEl   = navbarEl.find(s.toggleTrigger),
    toggle      = s.toggle;

    function offcanvasHide() {
      offcanvasEl.removeClass(toggle);

      setTimeout(function () {
        offcanvasEl.hide();
      }, 300);

      $('body').removeClass('modal-open navbar-offcanvas-open');
    }

    function offcanvasShow() {
      offcanvasEl.show();

      setTimeout(function () {
        offcanvasEl.addClass(toggle);
      }, 10);

      $('body').addClass('modal-open navbar-offcanvas-open');
    }

    function offcanvasToggle() {
      if (offcanvasEl.hasClass(toggle)) { offcanvasHide(); }
      else { offcanvasShow(); }
    }

    function touchyDrag(e, phase, $target, data) {
      var offcanvasEl = $(e.target).closest('.navbar-offcanvas');

      if (phase === 'move') {
        var yDelta = data.movePoint.y - data.lastMovePoint.y,
        xDelta = data.movePoint.x - data.lastMovePoint.x;

        if (offcanvasEl.hasClass('right')) {
          if (yDelta > -8 && yDelta < 8) {
            if (xDelta > 0 && xDelta !== 0) { offcanvasHide(); }
          }
        }
        else {
          if (yDelta > -8 && yDelta < 8) {
            if (xDelta < 0 && xDelta !== 0) { offcanvasHide(); }
          }
        }
      }
    }

    triggerEl.on('click', function (e) {
      var target = $(e.target);
      if (
        !target.hasClass('no-offcanvas-trigger') &&
        !target.hasClass('offcanvas-inner') &&
        !target.hasClass('dropdown-toggle') &&
        !target.closest('a').hasClass('dropdown-toggle')
      ) { offcanvasToggle(); }
    });

    // if touchy.js is loaded
    if ('touchy-drag') {
      offcanvasEl.on('touchy-drag', touchyDrag);
      offcanvasEl.find('*').not('a').on('touchy-drag', touchyDrag);
    }
  });
  };
}(jQuery));
