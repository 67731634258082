(function ($) {
  'use strict';
  $.fn.loginSwap = function (settings) {
    return this.each(function () {
      var s = $.extend({
          login: '.login-target',
          logout: '.logout-target',
          testEl: '#a2z-welcome-message'
        }, settings),

        $parent = $(this),
        $test = $(s.testEl),
        $login = $parent.find(s.login),
        $logout = $parent.find(s.logout);

      function toggleLogin() {
        $login.toggleClass('hidden');
        $logout.toggleClass('hidden');
      }

      if ($test.length) toggleLogin();
    });
  };
}(jQuery));
