PESAPP.countdown = (function(m, $) {

  m.countdown = {};
  m.options = {
    endtime: false,
    template: 'base-countdown'
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    var interval;
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      self.options.endtime = $(self.view).data('endtime') || self.options.endtime;
      self.getTemplate();

      interval = setInterval(function() {
        self = _updateHelper(self, interval);
        return self;
      }, 100);
    });
	};

  function _updateHelper(self, interval) {
    self.countdown = _countHelper(self.options.endtime);
    self.updateTemplate();
    if (self.countdown.total <= 0) {
      clearInterval(interval);
    }

    return self;
  }

  _countHelper = function(endtime) {
    var d = new Date();
    var y = d.getFullYear();
    var t = Date.parse(endtime) - Date.parse(d);
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = (Math.floor((t / (1000 * 60 * 60)) % 24) - 1);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));

    return {
      total: t,
      days: days < 0 ? 0 : days,
      hours: _zeroHelper(hours),
      minutes: _zeroHelper(minutes),
      seconds: _zeroHelper(seconds)
    };
  }

  function _zeroHelper(number) {
    if (number < 0) { number = '00'; }
		else if (number < 10) { number = '0' + number; }

    return number;
	}

  return m;

}(PESAPP.countdown || {}, jQuery));
