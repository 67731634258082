// Uses YQL to access RSS data via XML
// Example usage:
// var rssFeed = PESAPP.extend(PESAPP.rssFeed, config).init();

PESAPP.rssFeed = (function(m, $) {

  m.options = {
    alias: 'query.results.rss.channel.item',
    template: 'base-rss-feed',
    ajax: {
      callback: _callback
    },
    yql: {
      mode: true,
      src: 'xml'
    }
  };

	m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();

      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        self.data.items = holder.items;
        self.options.ajax.callback(self);
        self.updateTemplate();
      });

  		return self;
    });
	};

  function _callback(self) {
    var data = self.data;
    var items = [];

    $.each(data.items, function(i, item) {
      item.description = PESAPP.stripHtml(item.description, function(html) {
        return html.replace('read more', '');
      });
      items.push(item);
    });

    data.items = items;
    data.model = data.items;

    return self;
  }

  return m;

}(PESAPP.rssFeed || {}, jQuery));
